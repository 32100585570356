<template>
  <UiEmptyState
    :image-src="require('@/assets/img/something_went_wrong_error.svg')"
    :title-text="$t('Sorry, we could not create the label')"
  >
    <template #description>
      <p>{{ $t('An error has occurred and we could not create the return label.') }}</p>
      <p class="ui-margin:bottom-none">
        {{ $t('Please, contact us.') }}
      </p>
    </template>
    <template #actions>
      <UiButton
        v-if="supportUrl"
        mode="primary"
        class="main-button"
      >
        <a
          class="main-button__btn"
          :style="brandStyle"
          :href="supportUrl"
        >{{ $t('Support') }}</a>
      </UiButton>
      <UiButton
        v-else-if="brandWebsiteUrl"
        mode="primary"
        class="main-button"
      >
        <a
          class="main-button__btn"
          :style="brandStyle"
          :href="brandWebsiteUrl"
        >{{ $t('Visit the shop') }}</a>
      </UiButton>
    </template>
  </UiEmptyState>
</template>
<script>
import { brandColourMixin, allUrls } from '@/components/mixins'
import { UiButton, UiEmptyState } from '@sendcloud/ui-library'

export default {
  name: 'AnnouncementFailedError',
  components: {
    UiButton,
    UiEmptyState,
  },
  mixins: [brandColourMixin, allUrls],
}
</script>
